export default [
  {
    path: '/users/list',
    name: 'list-user',
    component: () => import('@/views/management-user/users/pages/List.vue'),
    meta: {
      parent: 'list-user',
      pageTitle: 'Danh sách người dùng',
      breadcrumb: [
        {
          text: 'Danh sác người dùng',
          to: '/users/list',
        },
      ],
    },
  },
  {
    path: '/users/edit/:id',
    name: 'edit-user',
    meta: {
      parent: 'list-user',
      pageTitle: 'Sửa thông tin người dùng',
      breadcrumb: [
        {
          text: 'Danh sách người dùng',
          to: '/users/list',
        },
        {
          text: 'Sửa thông tin',
          active: true,
        },
      ],
    },
    component: () => import('@/views/management-user/users/pages/Create.vue'),
  },
  {
    path: '/users/create',
    name: 'create-user',
    meta: {
      parent: 'list-user',
      pageTitle: 'Thêm người dùng',
      breadcrumb: [
        {
          text: 'Danh sách người dùng',
          to: '/users/list',
        },
        {
          text: 'Thêm người dùng',
          active: true,
        },
      ],
    },
    component: () => import('@/views/management-user/users/pages/Create.vue'),
  },
  {
    path: '/users/import-user-to-file',
    name: 'import-user',
    meta: {
      parent: 'list-user',
      pageTitle: 'Thêm người dùng từ file',
      breadcrumb: [
        {
          text: 'Danh sách người dùng',
          to: '/users/list',
        },
        {
          text: 'Thêm người dùng',
          active: true,
        },
      ],
    },
    component: () => import('@/views/management-user/users/pages/components/ListImportFile.vue'),
  },
]
