import Vue from 'vue'
import {
  ToastPlugin, ModalPlugin, BootstrapVue, BootstrapVueIcons,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
// import CKEditor from 'ckeditor5-vuejs'
import Vuelidate from 'vuelidate'
// import 'vue-progress-path/dist/vue-progress-path.css'
// import VueProgress from 'vue-progress-path'

// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'sweetalert2/dist/sweetalert2.min.css'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Loading-path
// Vue.use(VueProgress, {
//   // defaultShape: 'circle',
// })

Vue.use(VueSweetalert2)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
// Vue.use(CKEditor)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// Custome my font
require('@core/assets/fonts/custome/custome/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

// eslint-disable-next-line consistent-return
Vue.filter('formatDate', value => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})
// eslint-disable-next-line consistent-return
Vue.filter('formatDateYears', value => {
  if (value) {
    return moment(String(value)).format('YYYY')
  }
})

// eslint-disable-next-line consistent-return
Vue.filter('formatTime', value => {
  if (value) {
    return moment(String(value)).format('HH:mm')
  }
})

Vue.filter('formatSecond', value => {
  if (value === null) { return '-' }
  if (value >= 3600) {
    const hour = Math.floor(value / 3600)
    const minute = Math.floor((value % 3600) / 60)
    const second = value - (hour * 3600 + minute * 60)
    return second === 0 ? `${hour}h${minute}m` : `${hour}h${minute}m${second}s`
  }
  if (value >= 60 && value < 3600) {
    const minute = Math.floor(value / 60)
    const second = value - (minute * 60)
    return second === 0 ? `${minute}m` : `${minute}m${second}s`
  }
  if (value < 60 && value >= 0) {
    return `${value}s`
  }
  return 0
})

Vue.prototype.$lang = 'lang'
Vue.prototype.$language = () => (localStorage.getItem('lang') ? localStorage.getItem('lang') : 'vi')
Vue.prototype.$toastPosition = 'b-toaster-top-right'
Vue.prototype.$userData = () => JSON.parse(localStorage.getItem('userData'))
Vue.prototype.$serverfile = process.env.VUE_APP_BASE_API

new Vue({
  router,
  store,
  i18n,
  moment,
  render: h => h(App),
}).$mount('#app')
