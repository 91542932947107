import axios from '@axios'
import api from '../api'

const state = {
  dataEdit: [],
  alert: {
    name: 'Không có thông báo mới',
    color: 'success',
  },
  dataVaild: [],
  dataNoValid: [],
}

const getters = {
}

const mutations = {
  // eslint-disable-next-line no-shadow
  EDIT(state, data) {
    state.dataEdit = data
  },
  // eslint-disable-next-line no-shadow
  ALERT(state, data) {
    state.alert.name = data.name
    state.alert.color = data.color
  },
  // eslint-disable-next-line no-shadow
  SET_DATA_VALID(state, data) {
    state.dataVaild = data
  },
  // eslint-disable-next-line no-shadow
  SET_NO_DATA_VALID(state, data) {
    state.dataNoValid = data
  },
}

const actions = {
  // ==================================================Lấy dữ liệu bảng co phân trang =================================
  // eslint-disable-next-line consistent-return
  async fecthDataListST({ commit }, data) {
    try {
      const resuft = await api.fetchDataList(data)
      if (resuft.status === 200) {
        return resuft.data
      }
    } catch (e) {
      return null
    }
  },
  // ====================================================Lấy dữ liệu theo ID ===================================
  // eslint-disable-next-line consistent-return
  async fetchdataIdST({ commit }, data) {
    try {
      const resuft = await axios.get(`/Bussiness/bussiness/${data}`)
      if (resuft.status === 200) {
        commit('EDIT', resuft.data)
        return resuft.data
      }
    } catch (e) {
      // console.log(e)
    }
  },
  //= =================================================Lấy combo box loại doanh nghiêp
  // eslint-disable-next-line consistent-return
  async fecthDataBusinessType() {
    try {
      const resuft = await axios.get('/BussinessType/get-combobox-bussiness-type')
      if (resuft.status === 200) {
        return resuft
      }
    } catch (e) {
      return null
    }
  },
  //= =================================================Lấy combo box người đại diện
  // eslint-disable-next-line consistent-return
  async fecthDataOfficalProfileCombobox() {
    try {
      const resuft = await axios.get('/OfficalProfile/get-combobox-offical-profile')
      if (resuft.status === 200) {
        return resuft.data
      }
    } catch (e) {
      return null
    }
  },
  // ====================================lấy dữ liệu theo ID người đại diện ==================================
  // eslint-disable-next-line consistent-return
  async fetchUserId({ commit }, data) {
    try {
      const resuft = await axios.get(`OfficalProfile/officalProfiles/${data}`)
      if (resuft.status === 200) {
        return resuft.data
      }
    } catch (e) {
      const resuft = []
      return resuft
    }
  },
  //= =================================================Lấy danh sách tỉnh==============//
  // eslint-disable-next-line consistent-return
  async fecthProvinces() {
    try {
      const resuft = await axios.get('/OfficalProfile/provinces')
      if (resuft.status === 200) {
        return resuft.data
      }
    } catch (e) {
      return null
    }
  },
  //= =================================================Lấy danh sách tỉnh==============//
  // eslint-disable-next-line consistent-return
  async fecthDistricts({ commit }, data) {
    try {
      const resuft = await axios.get(`/OfficalProfile/districts/${data}`)
      if (resuft.status === 200) {
        return resuft.data
      }
    } catch (e) {
      const resuft = []
      return resuft
    }
  },
  //= =================================================Lấy danh sách tỉnh==============//
  // eslint-disable-next-line consistent-return
  async fecthWards({ commit }, data) {
    try {
      const resuft = await axios.get(`/OfficalProfile/wards/${data}`)
      if (resuft.status === 200) {
        return resuft.data
      }
    } catch (e) {
      const resuft = []
      return resuft
    }
  },
  // ===================================================Thêm =============================================//
  async addBusiness({ commit }, dataAdd) {
    try {
      const addBusiness01 = await axios.post('/Bussiness/bussinesses', dataAdd)
      if (addBusiness01.status === 200) {
        commit('ALERT', { name: 'Thêm thành công!', color: 'success' })
      }
    } catch (e) {
      commit('ALERT', { name: 'Thêm không thành công!', color: 'danger' })
    }
  },
  // Kiểm tra trùng mã code
  // eslint-disable-next-line consistent-return
  async checkCodeST({ commit }, code) {
    try {
      const data01 = await axios.get('/Bussiness/existCode', { params: code })
      if (data01.status === 200) {
        if (data01.data === true) {
          commit('ALERT', 'Nhập mã bị trùng!')
        }
        return data01.data
      }
    } catch (e) {
      // console.log(`lỗi rồi ${e}`)
    }
  },
  // Kiểm tra trùng mã code
  // eslint-disable-next-line consistent-return
  async checkEmailST({ commit }, email) {
    try {
      const data01 = await axios.get('/Bussiness/existCode', { params: email })
      if (data01.status === 200) {
        if (data01.data === true) {
          commit('ALERT', 'Nhập email bị trùng!')
        }
        return data01.data
      }
    } catch (e) {
      // console.log(`lỗi rồi ${e}`)
    }
  },
  //* *******edit ********8 */
  async editST({ commit }, dataAdd) {
    try {
      const PostEditJod = await axios.put('/Bussiness/bussiness', dataAdd)
      if (PostEditJod.status === 200) {
        commit('ALERT', { name: 'Sửa thành công!', color: 'success' })
      }
    } catch (e) {
      commit('ALERT', { name: 'Sửa không thành công!', color: 'success' })
    }
  },
  // ============Làm việc với file=============
  // --------------dowload file
  // file mẫu
  async dowloadFile(txt) {
    try {
      axios({
        url: '/Bussiness/generateTemplate', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file mẫu doanh nghiệp.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  // file excel
  async downloadExcelAll() {
    try {
      axios({
        url: '/Bussiness/export-excel', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'DoanhNghiep.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  // ----------------import file
  // eslint-disable-next-line consistent-return
  async getApiExcel({ commit }, data) {
    try {
      const apiExcel = await axios.post('/Bussiness/import-bussiness-from-json', data)
      if (apiExcel.status === 200) {
        commit('SET_DATA_VALID', apiExcel.data.data.filter(e => e.isSuccess === true))
        commit('SET_NO_DATA_VALID', apiExcel.data.data.filter(e => e.isSuccess === false))
        return {
          oke: true,
          data: apiExcel.data.data,
        }
      }
    } catch (e) {
      return {
        oke: false,
        message: e,
      }
    }
  },
  // xóa doanh nghiệp
  // eslint-disable-next-line consistent-return
  async deleteST({ commit }, data) {
    try {
      const data01 = await axios.post('/Bussiness/delete-bussiness', data)
      if (data01.status === 200) {
        commit('ALERT', { name: 'Xóa Thành Công!', color: 'success' })
        return data01
      }
    } catch (error) {
      commit('ALERT', { name: 'Xóa không thành công!', color: 'danger' })
      return null
    }
  },
  // kích hoạt tài khoản
  // eslint-disable-next-line consistent-return
  async active({ commit }, id) {
    try {
      const result = await axios.post(`Account/active-account/${id}`)
      if (result.status === 200) {
        commit('ALERT', { name: 'Kích hoạt thành công!', color: 'success' })
        return result
      }
    } catch (error) {
      commit('ALERT', { name: 'Kích hoạt không thành công!', color: 'danger' })
      return null
    }
  },

  // Hủy kích hoạt tài khoản
  // eslint-disable-next-line consistent-return
  async unActive({ commit }, id) {
    try {
      const result = await axios.post(`Account/disable-account/${id}`)
      if (result.status === 200) {
        commit('ALERT', { name: 'Hủy kích hoạt thành công!', color: 'success' })
        return result
      }
    } catch (error) {
      commit('ALERT', { name: 'Hủy kích hoạt không thành công!', color: 'danger' })
      return null
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
