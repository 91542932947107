/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import axios from '@axios'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = () => ({
  dataListOrganization: [],
  dataOrganizationChildren: [],
  dataValidUser: [],
  dataNoValidUser: [],
  dataTooltip: [],
  dataOrganizationUpdated: [],
})

const getters = {
  dataListOrganization: state => state.dataListOrganization,
  dataOrganizationChildren: state => state.dataOrganizationChildren,
  dataValidUser: state => state.dataValidUser,
  dataNoValidUser: state => state.dataNoValidUser,
  dataTooltip: state => state.dataTooltip,
  dataOrganizationUpdated: state => state.dataOrganizationUpdated,
}

const actions = {
  // Thông tin người dùng
  async accountInfo() {
    try {
      const { data } = await axios.get('account/accountInfo')
      return data
    } catch {
      return null
    }
  },

  // Gọi dánh sách cơ cấu tổ chức cha
  async doFetchListOrganization({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.ORGANIZATION_TREE_ROOT, { params: payload })
      commit('SET_DATA_LIST_ORGANIZATION', data)
    } catch (e) {
      return null
    }
  },

  // Gọi danh sách cơ cấu tổ chức con
  async doFetchOrganizationChildren({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.ORGANIZATION_CHILDREN, { params: payload })
      commit('SET_DATA_ORGANIZATION_CHILDREN', data)
    } catch (e) {
      return null
    }
  },

  // Gọi danh sách cơ cấu tổ chức cần thêm mới
  async doFetchOrganizationUpdated({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.ORGANIZATION_UPDATED, { params: payload })
      commit('SET_DATA_ORGANIZATION_UPDATED', data)
    } catch (e) {
      return null
    }
  },

  // Download tập tin danh sách người dùng về máy
  async downloadSampleUsers() {
    try {
      axiosApiInstance({
        url: '/OfficalProfile/export-excel', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'NguoiDung.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      // if (apidowload.status === 200) {
      // }
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },

  // Download tập tin mẫu người dùng về máy
  async downloadExampleFileUsers() {
    try {
      axiosApiInstance({
        url: '/OfficalProfile/generateTemplate', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'NguoiDung.xlsm') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      // if (apidowload.status === 200) {
      // }
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },

  async getApiExcelUser({ commit }, data) {
    try {
      const apiExcel = await axiosApiInstance.post(ConstantsApi.IMPORT_FILE_USERS, data)
      if (apiExcel.status === 200) {
        commit('DATA_VALID_USER', apiExcel.data.data.filter(e => e.isSuccess === true))
        commit('DATA_NO_VALID_USER', apiExcel.data.data.filter(e => e.isSuccess === false))
        commit('DATA_TOOLTIP', apiExcel)
        return apiExcel
      }
      // eslint-disable-next-line no-empty
    } catch (e) { }
  },

  // Up ảnh đại diện
  async uploadFile(ctx, model) {
    const formData = new FormData()
    formData.append('IsSecure', model.isSecure)
    formData.append('formFile', model.files)
    // if (model.IsBackground) { formData.append('IsBackground ', model.IsBackground) }
    if (model.fileType) formData.append('FileType', model.fileType)
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData) formData.append('UserId', userData.userId)
    try {
      const { data } = await axiosApiInstance.post(ConstantsApi.SERVER_FILE_UPLOAD, formData)
      return data
    } catch (err) {
      return err.response.data
    }
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_DATA_LIST_ORGANIZATION(state, data) {
    state.dataListOrganization = data.data
  },
  SET_DATA_ORGANIZATION_CHILDREN(state, data) {
    state.dataOrganizationChildren = data.data
  },
  DATA_VALID_USER(state, data) {
    state.dataValidUser = data
  },
  DATA_NO_VALID_USER(state, data) {
    state.dataNoValidUser = data
  },
  DATA_TOOLTIP(state, apiExcel) {
    state.dataTooltip = apiExcel.data.data
  },
  SET_DATA_ORGANIZATION_UPDATED(state, data) {
    state.dataOrganizationUpdated = data.data
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
