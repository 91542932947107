import axios from '@axios'

// Lấy danh sách người dùng phân trang
async function fetchListByofBusiness(payload) {
  return axios.get('BussinessType/bussinessTypes', { params: payload })
}
// =================================================================== tải file mẫu từ file exel ======================//
async function DownloadExelByofBusiness() {
  return axios.get('/BussinessType/generateTemplate')
}

// =====================================================================kiểm tra file từ exel==========================//
async function CheckAPIExcel(data) {
  return axios.post('/BussinessType/import-bussiness-type-from-json', data)
}
async function checkCodeAPI(data) {
  return axios.get('/BussinessType/existCode', { params: { code: data } })
}
async function checkNameAPI(data) {
  return axios.get('/BussinessType/existName', { params: { name: data } })
}
export default {
  fetchListByofBusiness,
  CheckAPIExcel,
  checkCodeAPI,
  checkNameAPI,
}
