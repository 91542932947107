export default [
  {
    path: '/group-new/list',
    name: 'group-new-list',
    component: () => import('@/views/category-manager/group_new.vue'),
    meta: {
      pageTitle: 'Danh mục nhóm tin tức',
      breadcrumb: [
        // {
        //   text: 'Doanh nghiệp',
        //   to: '/business',
        // },
        {
          text: 'nhóm tin tức',
          active: true,
        },
      ],
    },
  },
  {
    path: '/group-about/list',
    name: 'group-about-list',
    component: () => import('@/views/category-manager/group_about.vue'),
    meta: {
      pageTitle: 'Danh mục nhóm giới thiệu',
      breadcrumb: [
        {
          text: 'nhóm giới thiệu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/specification/list',
    name: 'specification-list',
    component: () => import('@/views/category-manager/specification.vue'),
    meta: {
      pageTitle: 'Danh mục nhóm thông số kỹ thuật',
      breadcrumb: [
        {
          text: 'nhóm thông số kỹ thuật',
          active: true,
        },
      ],
    },
  },
]
