export default {
  LIST_USERS: 'OfficalProfile/officalProfiles',
  ACTIVE_ACCOUNT: 'Account/active-account/',
  UN_ACTIVE_ACCOUNT: 'Account/disable-account/',
  DELETE_USERS: 'OfficalProfile/delete-officalProfiles',
  CHANGE_PASSWORD: 'Account/reissue-password',
  IMPORT_FILE_USERS: 'OfficalProfile/import-user-from-json',
  IMPORT_AVATAR_USERS: 'OfficalProfile/uploadAvatar',
  COMBOBOX_POSITION: 'Position/get-combobox-position',
  ORGANIZATION_TREE_ROOT: 'OrganizationStructure/get-treeview-root',
  ORGANIZATION_CHILDREN: 'OrganizationStructure/get-treeview-childRen',
  ORGANIZATION_UPDATED: 'OrganizationStructure/get-treeview-from-update',
  DATA_USER_ID: 'OfficalProfile/officalProfiles/',
  LIST_GROUP_USERS: 'OfficalProfile/list-group-user-in-offical-profile',
  LIST_ADD_GROUP_USERS: 'GroupUser/list-group-user-not-register',
  DELETE_GROUP_USERS: 'OfficalProfile/delete-group-user-in-offical-profile',
  ADD_GROUP_USERS: 'OfficalProfile/add-group-to-user',
  SERVER_FILE_UPLOAD: 'ServerFile/upload-image',
}
