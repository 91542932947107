import axios from '@axios'
import api from '../api'

const state = {
  dataTableState: '',
  alertAddUserGroup: '',
  dataValid: '',
  dataNoValid: '',
  dataEdit: '',
}

const getters = {
}

const mutations = {
  // eslint-disable-next-line no-shadow
  FETCH_DATA_TABLE(state, data) {
    state.dataTableState = data
  },
  // eslint-disable-next-line no-shadow
  ALERT_ADD_USER_GROUP(state, data) {
    state.alertAddUserGroup = data
  },
  // eslint-disable-next-line no-shadow
  DATA_VALID(state, data) {
    state.dataValid = data
  },
  // eslint-disable-next-line no-shadow
  DATA_NO_VALID(state, data) {
    state.dataNoValid = data
  },
  // eslint-disable-next-line no-shadow
  DATA_EDIT(state, data) {
    state.dataEdit = data
  },
}

const actions = {
  // ==================================================Lấy dữ liệu bảng co phân trang =================================
  // eslint-disable-next-line consistent-return
  async fetchByofBusiness({ commit }, data) {
    try {
      const resuft = await api.fetchListByofBusiness(data)
      if (resuft.status === 200) {
        commit('FETCH_DATA_TABLE', resuft.data.data.pageLists)
        return resuft.data
      }
    } catch (e) {
      return null
    }
  },
  // ====================================================Lấy dữ liệu theo ID ===================================
  // eslint-disable-next-line consistent-return
  async fetchToId({ commit }, data) {
    try {
      const resuft = await axios.get(`/BussinessType/bussinessTypes/${data}`)
      if (resuft.status === 200) {
        commit('DATA_EDIT', [resuft.data])
        return resuft.data
      }
    } catch (e) {
      return null
    }
  },
  //= =========================Kiểm tra mã có trùng hay không===============================//
  // eslint-disable-next-line consistent-return
  async checkCodeStore({ commit }, data) {
    try {
      const checkCode = await api.checkCodeAPI(data)
      if (checkCode.status === 200) {
        if (checkCode.data === true) {
          commit('ALERT_ADD_USER_GROUP', 'Nhập mã loại hình doanh nghiệp bị trùng')
        }
        return checkCode.data
      }
    } catch (e) {
      return null
    }
  },
  //= =========================Kiểm tra có trùng tên hay không===============================//
  // eslint-disable-next-line consistent-return
  async checkNameStore({ commit }, data) {
    try {
      const checkName = await api.checkNameAPI(data)
      if (checkName.status === 200) {
        if (checkName.data === true) {
          commit('ALERT_ADD_USER_GROUP', 'Nhập tên loại hình doanh nghiệp bị trùng')
        }
        return checkName.data
      }
    } catch (e) {
      return null
    }
  },
  //= =================================sửa ===============================================//
  // eslint-disable-next-line consistent-return
  async editStore({ commit }, data) {
    try {
      const PostEditJod = await axios.put('/BussinessType/bussinessTypes', data)
      if (PostEditJod.status === 200) {
        commit('ALERT_ADD_USER_GROUP', 'Sửa Thành Công!')
      }
    } catch (e) {
      return null
    }
  },

  // ===================================================Thêm kiểu doan nghiệp==============================================//
  // eslint-disable-next-line consistent-return
  async addStore({ commit }, dataAdd) {
    try {
      const addUser = await axios.post('/BussinessType/bussinessTypes', dataAdd)
      if (addUser.status === 200) {
        commit('ALERT_ADD_USER_GROUP', 'Thêm Thành Công!')
      }
    } catch (e) {
      return null
    }
  },
  // =============================== download file mẫu về máy ===============================================
  // eslint-disable-next-line consistent-return
  async downloadSampleUserGroup() {
    try {
      axios({
        url: '/BussinessType/generateTemplate', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file mẫu loại hình doanh nghiệp.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      return null
    }
  },
  // =============================== download file excel về máy ===============================================
  // eslint-disable-next-line consistent-return
  async downloadExcelAll() {
    try {
      axios({
        url: '/BussinessType/export-excel', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'LoaiHinhDoanhNghiep.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      return null
    }
  },
  //= ============================================Kiểm tra tính hợp lên khi thêm file Exel================================//
  // eslint-disable-next-line consistent-return
  async getApiExcel({ commit }, data) {
    try {
      const apiExcel = await api.CheckAPIExcel(data)
      if (apiExcel.status === 200) {
        commit('DATA_VALID', apiExcel.data.data.filter(e => e.isSuccess === true))
        commit('DATA_NO_VALID', apiExcel.data.data.filter(e => e.isSuccess === false))
        return {
          oke: true,
          data: apiExcel.data.data,
        }
      }
    } catch (e) {
      return {
        oke: false,
        messageErr: e,
      }
    }
  },

  // eslint-disable-next-line consistent-return
  async getAllComboBox(xrt, id) {
    try {
      const data = JSON.stringify(id)
      const resuft = await axios.get('/BussinessType/get-combobox-bussiness-type', { params: { ids: data } })
      if (resuft.status === 200) {
        return resuft
      }
    } catch (e) {
      return null
    }
  },
  // eslint-disable-next-line consistent-return
  async deleteUserGroup({ commit }, model) {
    try {
      const data = await axios.post(`BussinessType/delete-bussinessTypes?positionId=${model.positionId}`, model.id)
      return data
    } catch (error) {
      return null
    }
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
