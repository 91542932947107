export default {
  CREATE_CAR_CATEGORY: 'CarCatalog/create',
  EDIT_CAR_CATEGORY: 'CarCatalog/update',
  DELETE_CAR_CATEGORY: 'CarCatalog/delete',
  GET_PAGING_CAR_CATEGORY: 'CarCatalog/get-paging',
  DETAIL_CAR_CATEGORY: 'CarCatalog/get-by-id/',
  UPDATE_DETAIL_CAR_CATEGORY: 'CarCatalog/update-detail',
  GET_DETAIL_CAR_CATEGORY: 'CarCatalog/get-detail',
  GET_DETAIL_BY_ID_CAR_CATEGORY: 'CarCatalog/get-detail-by-id',
  DELETE_DETAIL_CAR_CATEGORY: 'CarCatalog/delete-detail',

  CREATE_CAR_CATEGORY_VERSION: 'CarCatalogDetailVersion/create',
  EDIT_CAR_CATEGORY_VERSION: 'CarCatalogDetailVersion/update',
  DELETE_CAR_CATEGORY_VERSION: 'CarCatalogDetailVersion/delete',
  GET_PAGING_CAR_CATEGORY_VERSION: 'CarCatalogDetailVersion/get-paging',
  DETAIL_CAR_CATEGORY_VERSION: 'CarCatalogDetailVersion/get-by-id/',

  CREATE_CAR_CATEGORY_DETAIL_SPEC_VERSION: 'CarCatalogDetailVersionSpecification/create',
  EDIT_CAR_CATEGORY_DETAIL_SPEC_VERSION: 'CarCatalogDetailVersionSpecification/update',
  DELETE_CAR_CATEGORY_DETAIL_SPEC_VERSION: 'CarCatalogDetailVersionSpecification/delete',
  GET_PAGING_CAR_CATEGORY_DETAIL_SPEC_VERSION: 'CarCatalogDetailVersionSpecification/get-paging',
  DETAIL_CAR_CATEGORY_DETAIL_SPEC_VERSION: 'CarCatalogDetailVersionSpecification/get-by-id/',

  UPDATE_CAR_HTML: 'CarCatalogHtml/update',
  GET_BY_ID_CAR_HTML: 'CarCatalogHtml/get-by-id',

  GET_CAR_LIST: 'CarCatalog/get-car-list',
  GET_CONTACT_SERVICE_LIST: 'Contact/get-contact-service',
}
