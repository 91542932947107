import { $themeConfig } from '@themeConfig'

const menus = JSON.parse(localStorage.getItem('menuItems'))
export default {
  namespaced: true,
  state: {
    menuItems: menus !== null ? menus : [],
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    UPDATE_MENU_ITEMS(state, val) {
      state.menuItems = val
    },
  },
  actions: {

  },
}
