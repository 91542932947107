import axios from '@axios'

const state = {
  dataTableState: '',
  alertAddUserGroup: '',
  dataValidUserGroup: '',
  dataNoValidUserGroup: '',
  dataEditUserGroup: '',
}

const getters = {
}

const mutations = {
  // eslint-disable-next-line no-shadow
  FETCH_DATA_TABLE(state, data) {
    state.dataTableState = data
  },
  // eslint-disable-next-line no-shadow
  ALERT_ADD_USER_GROUP(state, data) {
    state.alertAddUserGroup = data
  },
  // eslint-disable-next-line no-shadow
  DATA_VALID_USER_GROUP(state, data) {
    state.dataValidUserGroup = data
  },
  // eslint-disable-next-line no-shadow
  DATA_NO_VALID_USER_GROUP(state, data) {
    state.dataNoValidUserGroup = data
  },
  // eslint-disable-next-line no-shadow
  DATA_EDIT_USER_GROUP(state, data) {
    state.dataEditUserGroup = data
  },
}

const actions = {
  // ==================================================Lấy dữ liệu bảng co phân trang =================================
  // eslint-disable-next-line consistent-return
  async fetchUserGroup({ commit }, data) {
    try {
      const resuft = await axios.get('GroupUser/groupUsers', { params: data })
      if (resuft.status === 200) {
        commit('FETCH_DATA_TABLE', resuft.data.data.pageLists)
        return resuft.data
      }
    } catch (e) {
      // console.log(e)
    }
  },
  // ====================================================Lấy dữ liệu theo ID ===================================
  // eslint-disable-next-line consistent-return
  async fetchUserGroupToId({ commit }, data) {
    try {
      const resuft = await axios.get(`/GroupUser/groupUsers/${data}`)
      if (resuft.status === 200) {
        commit('DATA_EDIT_USER_GROUP', [resuft.data])
        return resuft.data
      }
    } catch (e) {
      // console.log(e)
    }
  },
  //= =========================Kiểm tra có trùng tên nhóm người dùng hay không===============================//
  // eslint-disable-next-line consistent-return
  async checkCodeUserGroup({ commit }, dataCheckName) {
    try {
      const checkCode = await axios.get('GroupUser/existCode', { params: { code: dataCheckName } })
      if (checkCode.status === 200) {
        if (checkCode.data === true) {
          commit('ALERT_ADD_USER_GROUP', 'Nhập mã nhóm người dùng bị trùng!')
        }
        return checkCode.data
      }
    } catch (e) {
      // console.log(`lỗi rồi ${e}`)
    }
  },
  //= =========================Kiểm tra có trùng tên nhóm người dùng hay không===============================//
  // eslint-disable-next-line consistent-return
  async checkNameUserGroup({ commit }, dataCheckName) {
    try {
      const checkName = await axios.get('GroupUser/existName', { params: { name: dataCheckName } })
      if (checkName.status === 200) {
        if (checkName.data === true) {
          commit('ALERT_ADD_USER_GROUP', 'Nhập tên nhóm người dùng bị trùng!')
        }
        return checkName.data
      }
    } catch (e) {
      // console.log(`lỗi rồi ${e}`)
    }
  },
  // ===================================================Thêm nhóm người dùng==============================================//
  async addUserGroup({ commit }, dataAdd) {
    try {
      const addUser = await axios.post('GroupUser/groupUsers', dataAdd)
      if (addUser.status === 200) {
        commit('ALERT_ADD_USER_GROUP', 'Thêm Thành Công!')
      }
    } catch (e) {
      // console.log(`lỗi rồi ${e}`)
    }
  },
  // =============================== download file mẫu về máy ===============================================
  async downloadSampleUserGroup() {
    try {
      axios({
        url: '/GroupUser/generateTemplate', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file mẫu nhóm người dùng.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      // if (apidowload.status === 200) {
      // }
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  // =============================== download file all excel về máy ===============================================
  async downloadExcelAll() {
    try {
      axios({
        url: '/GroupUser/export-excel', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'NhomNguoiDung.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      // if (apidowload.status === 200) {
      // }
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  //= ============================================Kiểm tra tính hợp lên khi thêm file Exel================================//
  // eslint-disable-next-line consistent-return
  async getApiExcelUserGroup({ commit }, data) {
    try {
      const apiExcel = await axios.post('/GroupUser/import-group-user-from-json', data)
      if (apiExcel.status === 200) {
        commit('DATA_VALID_USER_GROUP', apiExcel.data.data.filter(e => e.isSuccess === true))
        commit('DATA_NO_VALID_USER_GROUP', apiExcel.data.data.filter(e => e.isSuccess === false))
        return {
          oke: true,
          data: apiExcel.data.data,
        }
      }
    } catch (e) {
      return {
        oke: false,
        messageErr: e,
      }
    }
  },
  // phần sửa
  async editUserGroup({ commit }, dataAdd) {
    try {
      const PostEditJod = await axios.put('GroupUser/groupUsers', dataAdd)
      if (PostEditJod.status === 200) {
        commit('ALERT_ADD_USER_GROUP', 'Sửa Thành Công!')
      }
    } catch (e) {
      // console.log(`lỗi rồi ${e}`)
    }
  },
  //  ========================-------------[ Lấy combobox ]------------------======================//
  // eslint-disable-next-line consistent-return
  async getAllComboBox(xrt, id) {
    try {
      const data = JSON.stringify(id)
      const resuft = await axios.get('/GroupUser/get-combobox-group-user', { params: { ids: data } })
      if (resuft.status === 200) {
        return resuft
      }
    } catch (e) {
      // console.log(e)
    }
  },
  //  ========================-------------[ Xóa file ]------------------======================//
  // eslint-disable-next-line consistent-return
  async deleteUserGroup({ commit }, model) {
    try {
      const data = await axios.post(`GroupUser/delete-groupUsers?groupUserId=${model.groupUserId}`, model.id)
      return data
    } catch (error) {
      return error.response.data
    }
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
