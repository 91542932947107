import axios from '@axios'

// Lấy danh sách người dùng phân trang
async function fetchListTrash(payload) {
  return axios.get('/RecycleBin/recycle-bin-users', { params: payload })
}

// khôi phục người dùng
async function restoreToTrash(data) {
  return axios.post('RecycleBin/recover-users', data)
}

// xóa người dùng
async function deleteToTrash(data) {
  return axios.post('RecycleBin/permanent-delete-users', data)
}

// Lấy danh sách nhóm người dùng phân trang
async function fetchListTrashGroup(payload) {
  return axios.get('/RecycleBin/recycle-bin-group-users', { params: payload })
}

// khôi phục nhóm người dùng
async function restoreToTrashGroup(data) {
  return axios.post('/RecycleBin/recover-group-users', data)
}

// xóa nhóm người dùng
async function deleteToTrashGroup(data) {
  return axios.post('/RecycleBin/permanent-delete-group-users', data)
}

export default {
  deleteToTrashGroup,
  restoreToTrashGroup,
  fetchListTrashGroup,
  fetchListTrash,
  restoreToTrash,
  deleteToTrash,
}
