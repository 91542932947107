export default [
  {
    path: '/slide-main/list',
    name: 'slide-main-list',
    component: () => import('@/views/slide-manager/pages/SlideMain.vue'),
    meta: {
      pageTitle: 'Slide trang chủ',
      breadcrumb: [
        {
          text: 'danh sách',
          active: true,
        },
      ],
    },
  },
]
