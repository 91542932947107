import Vue from 'vue'
import Vuex from 'vuex'

// Modules

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import users from '@/views/management-user/users/store'
import positionJob from '@/views/management-user/position-job/store/index'
import userGroup from '@/views/management-user/User-Group/store/index'
import TrashBin from '@/views/management-user/Trash-Bin/store/index'
import userType from '@/views/management-user/user-type/store/index'
import typeOfBusiness from '@/views/management-business/type-of-business/store/index'
import struct from '@/views/management-user/organization-struct/store/index'
import business from '@/views/management-business/business/store/index'
import businessSector from '@/views/management-business/business-sector/store/index'
import occupation from '@/views/management-workers/occupation-list/store/index'
import worker from '@/views/management-workers/worker/store/index'
import groupNew from '@/views/category-manager/group-new/store/index'
import carCatalog from '@/views/car-manager/car-catalog/store/index'
import libraryStore from '@/views/ndev-component/store/libraryStore/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    users,
    positionJob,
    userGroup,
    TrashBin,
    userType,
    typeOfBusiness,
    struct,
    business,
    businessSector,
    occupation,
    worker,
    groupNew,
    carCatalog,
    libraryStore,
  },
  strict: process.env.DEV,
})
