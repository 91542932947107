export default [
  {
    path: '/car-catalog/list',
    name: 'car-catalog-list',
    component: () => import('@/views/car-manager/car-catalog/pages/List.vue'),
    meta: {
      pageTitle: 'Quản lý xe',
      breadcrumb: [
        {
          text: 'loại xe',
          active: true,
        },
      ],
    },
  },
  {
    path: '/car-catalog/detail/:id',
    name: 'car-catalog-detail',
    component: () => import('@/views/car-manager/car-catalog/pages/Detail.vue'),
    meta: {
      pageTitle: 'Quản lý xe',
      breadcrumb: [
        {
          text: 'Loại xe',
          to: '/car-catalog/list',
        },
        {
          text: 'Chi tiết loại xe',
          active: true,
        },
      ],
    },
  },
  {
    path: '/car-catalog/overview/gioi-thieu/:id',
    name: 'car-catalog-overview-gioi-thieu',
    component: () => import('@/views/car-manager/car-catalog/pages/V4/V4Overview-GioiThieu.vue'),
    meta: {
      pageTitle: 'Quản lý xe',
      breadcrumb: [
        {
          text: 'Loại xe',
          to: '/car-catalog/list',
        },
        {
          text: 'Tổng quan: Giới thiệu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/car-catalog/version/detail/:id',
    name: 'car-catalog-version-version',
    component: () => import('@/views/car-manager/car-catalog/pages/components/Version/VersionDetail.vue'),
    meta: {
      pageTitle: 'Quản lý xe',
      breadcrumb: [
        {
          text: 'Loại xe',
          to: '/car-catalog/list',
        },
        {
          text: 'Thông số kỹ thuật',
          active: true,
        },
      ],
    },
  },
  {
    path: '/car-catalog/export-html/:id',
    name: 'car-catalog-export-html',
    component: () => import('@/views/car-manager/car-catalog/pages/components/ExportHtml.vue'),
    meta: {
      pageTitle: 'Quản lý xe',
      breadcrumb: [
        {
          text: 'Loại xe',
          to: '/car-catalog/list',
        },
        {
          text: 'Xuất mã HTML',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/car-catalog/overview',
  //   name: 'car-catalog-overview',
  //   component: () => import('@/views/car-manager/car-catalog/pages/components/Overview.vue'),
  //   meta: {
  //     pageTitle: 'Quản lý xe',
  //     breadcrumb: [
  //       {
  //         text: 'Loại xe',
  //         to: '/car-catalog/list',
  //       },
  //       {
  //         text: 'Tổng quan',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/car-catalog/design',
  //   name: 'car-catalog-design',
  //   component: () => import('@/views/car-manager/car-catalog/pages/components/Design.vue'),
  //   meta: {
  //     pageTitle: 'Quản lý xe',
  //     breadcrumb: [
  //       {
  //         text: 'Loại xe',
  //         to: '/car-catalog/list',
  //       },
  //       {
  //         text: 'Thiết kế',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/car-catalog/price',
  //   name: 'car-catalog-price',
  //   component: () => import('@/views/car-manager/car-catalog/pages/components/Price.vue'),
  //   meta: {
  //     pageTitle: 'Quản lý xe',
  //     breadcrumb: [
  //       {
  //         text: 'Loại xe',
  //         to: '/car-catalog/list',
  //       },
  //       {
  //         text: 'Phiên bản và giá',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/car-catalog/intelligent',
  //   name: 'car-catalog-intelligent',
  //   component: () => import('@/views/car-manager/car-catalog/pages/components/Intelligent.vue'),
  //   meta: {
  //     pageTitle: 'Quản lý xe',
  //     breadcrumb: [
  //       {
  //         text: 'Loại xe',
  //         to: '/car-catalog/list',
  //       },
  //       {
  //         text: 'Intelligent Mobility',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/car-catalog/operate',
  //   name: 'car-catalog-operate',
  //   component: () => import('@/views/car-manager/car-catalog/pages/components/Operate.vue'),
  //   meta: {
  //     pageTitle: 'Quản lý xe',
  //     breadcrumb: [
  //       {
  //         text: 'Loại xe',
  //         to: '/car-catalog/list',
  //       },
  //       {
  //         text: 'Vận hành',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
