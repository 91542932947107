const state = () => ({
  libraryStore_state_DataDetail: [],
  libraryStore_state_BreadCom: [],
  libraryStore_state_CarDetailModel: {
    id: '',
    name: '',
    content: '',
    position: 0,
    groupId: 0,
    groupFatherId: 0,
    typeId: '3E8D0A58-6876-4A68-B30E-F7C83959F2F6',
    detailFileModels: [{
      id: '',
      carCatalogDetailId: '',
      fileId: '',
      filePath: '',
      fileName: '',
      fileType: '',
    }],
  },
  libraryStore_state_ChildFolder: {
    reload: false,
    idParent: '',
  },
  libraryStore_state_ChildFile: {
    reload: false,
    idParent: '',
  },
  libraryStore_state_updateFolder: false,
  libraryStore_state_reloadFolder: false,
  libraryStore_state_reloadFile: false,
  libraryStore_state_resetQuillContent: {
    reset: false,
    idQuill: '',
  },
})

const getters = {
  // eslint-disable-next-line no-shadow
  libraryStore_getters_DataDetail: state => state.libraryStore_state_DataDetail,
  // eslint-disable-next-line no-shadow
  libraryStore_getters_BreadCom: state => state.libraryStore_state_BreadCom,
  // eslint-disable-next-line no-shadow
  libraryStore_getters_ChildFolder: state => state.libraryStore_state_ChildFolder,
  // eslint-disable-next-line no-shadow
  libraryStore_getters_ChildFile: state => state.libraryStore_state_ChildFile,
  // eslint-disable-next-line no-shadow
  libraryStore_getters_updateFolder: state => state.libraryStore_state_updateFolder,
  // eslint-disable-next-line no-shadow
  libraryStore_getters_reloadFolder: state => state.libraryStore_state_reloadFolder,
  // eslint-disable-next-line no-shadow
  libraryStore_getters_reloadFile: state => state.libraryStore_state_reloadFile,
  // eslint-disable-next-line no-shadow
  libraryStore_getters_resetFile: state => state.libraryStore_state_resetQuillContent,
  // eslint-disable-next-line no-shadow
  libraryStore_getters_carDetailModel: state => state.libraryStore_state_CarDetailModel,
}

const actions = {
  // eslint-disable-next-line no-shadow
  libraryStore_action_SetBreadCom({ commit }, payload) {
    commit('LIBRARY_STORE_MUTATION_SET_BREADCOM', payload)
  },
  libraryStore_action_ResetBreadCom({ commit }) {
    commit('LIBRARY_STORE_MUTATION_SET_BREADCOM', [
      {
        id: 0,
        idFolder: '0',
        name: 'Thư mục gốc',
      },
    ])
  },
  // eslint-disable-next-line no-shadow
  libraryStore_action_SetDataDetail({ commit }, payload) {
    commit('LIBRARY_STORE_MUTATION_SET_DATA_DETAIL', payload)
  },
  // eslint-disable-next-line no-shadow
  libraryStore_action_SetData_ChildFolder({ commit }, payload) {
    commit('LIBRARY_STORE_MUTATION_SET_DATA_CHILD_FOLDER', payload)
  },
  // eslint-disable-next-line no-shadow
  libraryStore_action_SetData_ChildFile({ commit }, payload) {
    commit('LIBRARY_STORE_MUTATION_SET_DATA_CHILD_FILE', payload)
  },
  // eslint-disable-next-line no-shadow
  libraryStore_action_updateFolder({ commit }, payload) {
    commit('LIBRARY_STORE_MUTATION_SET_UPDATE_FOLDER', payload)
  },
  // eslint-disable-next-line no-shadow
  libraryStore_action_reloadFolder({ commit }, payload) {
    commit('LIBRARY_STORE_MUTATION_SET_RELOAD_FOLDER', payload)
  },
  // eslint-disable-next-line no-shadow
  libraryStore_action_reloadFile({ commit }, payload) {
    commit('LIBRARY_STORE_MUTATION_SET_RELOAD_FILE', payload)
  },
  // eslint-disable-next-line no-shadow
  libraryStore_action_resetQuillContent({ commit }, payload) {
    commit('LIBRARY_STORE_MUTATION_SET_RESET_QUILL_CONTENT', payload)
  },
  // eslint-disable-next-line no-shadow
  libraryStore_action_carDetailModel({ commit }, payload) {
    commit('LIBRARY_STORE_MUTATION_SET_CAR_DETAIL_MODEL', payload)
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  LIBRARY_STORE_MUTATION_SET_BREADCOM(state, payload) {
    state.libraryStore_state_BreadCom = payload
  },
  // eslint-disable-next-line no-shadow
  LIBRARY_STORE_MUTATION_SET_DATA_DETAIL(state, payload) {
    state.libraryStore_state_DataDetail = payload
  },
  // eslint-disable-next-line no-shadow
  LIBRARY_STORE_MUTATION_SET_DATA_CHILD_FOLDER(state, payload) {
    state.libraryStore_state_ChildFolder = payload
  },
  // eslint-disable-next-line no-shadow
  LIBRARY_STORE_MUTATION_SET_DATA_CHILD_FILE(state, payload) {
    state.libraryStore_state_ChildFile = payload
  },
  // eslint-disable-next-line no-shadow
  LIBRARY_STORE_MUTATION_SET_UPDATE_FOLDER(state, payload) {
    state.libraryStore_state_updateFolder = payload
  },
  // eslint-disable-next-line no-shadow
  LIBRARY_STORE_MUTATION_SET_RELOAD_FOLDER(state, payload) {
    state.libraryStore_state_reloadFolder = payload
  },
  // eslint-disable-next-line no-shadow
  LIBRARY_STORE_MUTATION_SET_RELOAD_FILE(state, payload) {
    state.libraryStore_state_reloadFile = payload
  },
  // eslint-disable-next-line no-shadow
  LIBRARY_STORE_MUTATION_SET_RESET_QUILL_CONTENT(state, payload) {
    state.libraryStore_state_resetQuillContent = payload
  },
  // eslint-disable-next-line no-shadow
  LIBRARY_STORE_MUTATION_SET_CAR_DETAIL_MODEL(state, payload) {
    state.libraryStore_state_CarDetailModel = payload
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
