export default {
  GROUP_NEW_TREEVIEW_FROM_UPDATED: 'Category/get-treeview-from-update',
  GROUP_NEW_TREEVIEW_ROOT: 'Category/get-treeview-root',
  GROUP_NEW_TREEVIEW_CHILDREN: 'Category/get-treeview-childRen',
  IMPORT_FILE_GROUP_NEW: 'Category/import-Category-from-json',
  EDIT_GROUP_NEW: 'Category/update',
  DELETE_GROUP_NEW: 'Category/delete',
  CREATE_GROUP_NEW: 'Category/create',
  DETAIL_GROUP_NEW: 'Category/get-by-id/',
}
