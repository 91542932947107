import jwtDefaultConfig from './jwtDefaultConfig'
import { axiosApiInstance } from './axiosApiInstance'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = axiosApiInstance

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
    // Request Interceptor
    axiosApiInstance.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = localStorage.getItem('token')
        // const accessToken = localStorage.getItem(jwtDefaultConfig.storageTokenKeyName)
        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    axiosApiInstance.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const {
          config,
          response,
        } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken()
              .then(r => {
                this.isAlreadyFetchingAccessToken = false
                // eslint-disable-next-line default-case
                switch (r.data.code) {
                  case 200: {
                    // Update accessToken in localStorage
                    this.setToken(r.data.accessToken)
                    this.setRefreshToken(r.data.refreshToken)

                    this.onAccessTokenFetched(r.data.accessToken)

                    localStorage.setItem('token', `Bearer ${r.data.accessToken}`)
                    localStorage.setItem('refreshToken', r.data.refreshToken)
                    break
                  }
                  case 400: {
                    this.$router.push({ name: 'home' })
                    break
                  }
                  default: {
                    this.$router.push({ name: 'login' })
                    break
                  }
                }
              })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(axiosApiInstance(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axiosApiInstance.post(this.jwtConfig.loginEndpoint, ...args)
  }

  async accountInfo() {
    const res = await axiosApiInstance.get(this.jwtConfig.accountInfo)
    return res
  }

  register(...args) {
    return axiosApiInstance.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axiosApiInstance.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: localStorage.getItem('refreshToken'),
      accessToken: localStorage.getItem('token'),
    })
  }
}
