/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = () => ({
  dataValidUser: [],
  dataNoValidUser: [],
  dataTooltip: [],
  dataListBusinessSector: [],
  dataBusinessSectorChildren: [],
  dataBusinessSectorUpdated: [],
})

const getters = {
  dataValidUser: state => state.dataValidUser,
  dataNoValidUser: state => state.dataNoValidUser,
  dataTooltip: state => state.dataTooltip,
  dataListBusinessSector: state => state.dataListBusinessSector,
  dataBusinessSectorChildren: state => state.dataBusinessSectorChildren,
  dataBusinessSectorUpdated: state => state.dataBusinessSectorUpdated,
}

const mutations = {
  DATA_VALID_USER(state, data) {
    state.dataValidUser = data
  },
  DATA_NO_VALID_USER(state, data) {
    state.dataNoValidUser = data
  },
  DATA_TOOLTIP(state, apiExcel) {
    state.dataTooltip = apiExcel.data.data
  },
  SET_DATA_LIST_BUSINESS_SECTOR(state, data) {
    state.dataListBusinessSector = data.data
  },
  SET_DATA_BUSINESS_SECTOR_CHILDREN(state, data) {
    state.dataBusinessSectorChildren = data.data
  },
  SET_DATA_BUSINESS_UPDATED(state, data) {
    state.dataBusinessSectorUpdated = data.data
  },
}

const actions = {
  // Gọi danh sách cơ cấu tổ chức cần thêm mới
  async doFetchBusinessSectorUpdated({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.BUSINESS_SECTOR_TREEVIEW_UPDATED, { params: payload })
      commit('SET_DATA_BUSINESS_UPDATED', data)
    } catch (e) {
      return null
    }
  },

  // Gọi dánh sách cơ cấu tổ chức cha
  async doFetchListBusinessSector({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.BUSINESS_SECTOR_TREEVIEW_ROOT, { params: payload })
      commit('SET_DATA_LIST_BUSINESS_SECTOR', data)
    } catch (e) {
      return null
    }
  },

  // Gọi danh sách cơ cấu tổ chức con
  async doFetchBusinessSectorChildren({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.BUSINESS_SECTOR_TREEVIEW_CHILDREN, { params: payload })
      commit('SET_DATA_BUSINESS_SECTOR_CHILDREN', data)
    } catch (e) {
      return null
    }
  },

  // Download tập tin nội dung cơ cấu tổ chức về máy
  async downloadFileBusinessSector() {
    try {
      axiosApiInstance({
        url: '/BussinessSector/export-excel', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'LoaiHinhDoanhNghiep.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      return e.response
    }
  },

  // Download tập tin mẫu cơ cấu tổ chức về máy
  async downloadExampleFileBusinessSector() {
    try {
      axiosApiInstance({
        url: '/BussinessSector/generateTemplate', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'LoaiHinhDoanhNghuep.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      return e.response
    }
  },

  // Thêm cơ cấu tổ chức từ file excel
  async getApiExcelBusinessSector({ commit }, data) {
    try {
      const apiExcel = await axiosApiInstance.post(ConstantsApi.IMPORT_FILE_BUSINESS_SECTOR, data)
      if (apiExcel.status === 200) {
        commit('DATA_VALID_USER', apiExcel.data.data.filter(e => e.isSuccess === true))
        commit('DATA_NO_VALID_USER', apiExcel.data.data.filter(e => e.isSuccess === false))
        commit('DATA_TOOLTIP', apiExcel)
        return apiExcel
      }
      // eslint-disable-next-line no-empty
    } catch (e) { }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
