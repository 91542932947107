import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import user from './routes/users/user'
// import positionJob from './routes/users/positionJob'
import UserGroup from './routes/users/UserGroup'
import TrashBin from './routes/users/TrashBin'
import userType from './routes/users/userType'
// import typeOfBusiness from './routes/businesses/typeOfBusiness'
// import struct from './routes/users/struct'
// import business from './routes/businesses/business'
// import businessSector from './routes/businesses/businessSector'
// import healtClassification from './routes/businesses/healtClassification'
// import occupation from './routes/workers/occupation'
// import worker from './routes/workers/worker'
// import workerLicense from './routes/workers/workerLicense'
// import news from './routes/news'
// import occupationalSafetyHealth from './routes/occupational-safety-health'
// import occupationalSafetyClassification from './routes/safe/occupationalSafetyClassification'
// import accidentManagement from './routes/safe/accidentManagement'
// import workPermit from './routes/workers/workPermit'
// import connective from './routes/connective'
// import workerAge from './routes/statistical/statistical'
// import setting from './routes/setting/setting'
// import submitReport from './routes/businesses/submitReport'
// import inspectEquip from './routes/businesses/inspectEquip'
// import declareEquip from './routes/businesses/declareEquip'
// import manageEquip from './routes/businesses/manageEquip'
// import environmentalMonitoring from './routes/businesses/environmentalMonitoring'
// import foster from './routes/businesses/foster'
// import cost from './routes/businesses/cost'
// import training from './routes/businesses/training'
// import licensing from './routes/businesses/licensing'
// import declareAccident from './routes/businesses/declareAccident'
// import manageDoc from './routes/businesses/manageDoc'
// import archive from './routes/archive'
// import category from './routes/categoryFile'
// import classify from './routes/businesses/classifyFile'
// import statusReport from './routes/businesses/statusReport'
// import digitally from './routes/businesses/digitally-signed'
// import service from './routes/service'
// import message from './routes/message/message'
import groupNew from './routes/category-manager/group-new'
import carCatalog from './routes/car-manager/car-catalog'
import newContant from './routes/new-contant-manager/new-contant'
import slide from './routes/new-contant-manager/slide'
import contact from './routes/new-contant-manager/contact'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'dashboard-ecommerce' },
    },
    ...user,
    ...dashboard,
    ...pages,
    ...UserGroup,
    // ...positionJob,
    ...TrashBin,
    ...userType,
    // ...typeOfBusiness,
    // ...struct,
    // ...business,
    // ...businessSector,
    // ...news,
    // ...occupation,
    // ...occupationalSafetyHealth,
    // ...worker,
    // ...workerLicense,
    // ...occupationalSafetyClassification,
    // ...accidentManagement,
    // ...workPermit,
    // ...connective,
    // ...workerAge,
    // ...setting,
    // ...submitReport,
    // ...healtClassification,
    // ...inspectEquip,
    // ...declareEquip,
    // ...manageEquip,
    // ...environmentalMonitoring,
    // ...foster,
    // ...cost,
    // ...training,
    // ...licensing,
    // ...declareAccident,
    // ...manageDoc,
    // ...archive,
    // ...category,
    // ...classify,
    // ...statusReport,
    // ...digitally,
    // ...service,
    // ...message,
    ...groupNew,
    ...carCatalog,
    ...newContant,
    ...slide,
    ...contact,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
// Kiểm tra xem portal hoặc brand có tồn tại
// Đẩy về giao diện 404 nếu không tìm thấy
const checkPortal = async (next, to) => {
  const checkFlag = true
  // const checkFlag = await useJwt.checkPortalBranch()
  if (checkFlag) {
    let isLoggedIn = isUserLoggedIn()
    const iCheck = canNavigate(to)
    if (!iCheck) {
      // Redirect to login if not logged in
      isLoggedIn = isUserLoggedIn()
      // => Bị bắt lỗi chưa login sau khi F5 mà không rõ nguyên nhân =====================
      if (!isLoggedIn) {
        // const { data } = await axios.get(`${process.env.VUE_APP_BASE_API}/widget/get-all`)
        // if (data && data.data && data.data.length > 0) {
        //   return next({
        //     name: 'home-page',
        //   })
        // }
        return next({
          name: 'auth-login',
          query: { redirect: to.fullPath },
        })
      }
      // ===Giải mã token => xử lý tiếp ================
      return next()
      // If logged in => not authorized
      // return next({ name: 'misc-not-authorized' })
    }
    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      const userData = getUserData()
      next(getHomeRouteForLoggedInUser(userData ? userData.roles : null))
    }
    return next()
  }
  return next({ name: 'misc-error' })
}
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
router.beforeEach((to, _, next) => {
  if (to?.name === 'misc-error') return next()
  return checkPortal(next, to)
  // const isLoggedIn = isUserLoggedIn()
  // // if (isLoggedIn == null) return next({ name: 'auth-login' })
  // console.log(`Bat dau ===>: ${isLoggedIn}`)
  // console.log(`to ===>: ${to}`)
  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: 'auth-login' })
  //   console.log(`lan 2 ===>: ${isLoggedIn}`)
  //   // If logged in => not authorized
  //   return next({ name: 'misc-not-authorized' })
  // }
  //
  // // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData()
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  //   // =======Kiểm tra role========
  // }
  //
  // return next()
})

export default router
