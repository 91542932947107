/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = () => ({
  dataValidUser: [],
  dataNoValidUser: [],
  dataTooltip: [],
  dataListGroupNew: [],
  dataGroupNewChildren: [],
  dataGroupNewUpdated: [],
})

const getters = {
  dataValidUser: state => state.dataValidUser,
  dataNoValidUser: state => state.dataNoValidUser,
  dataTooltip: state => state.dataTooltip,
  dataListGroupNew: state => state.dataListGroupNew,
  dataGroupNewChildren: state => state.dataGroupNewChildren,
  dataGroupNewUpdated: state => state.dataGroupNewUpdated,
}

const mutations = {
  DATA_VALID_USER(state, data) {
    state.dataValidUser = data
  },
  DATA_NO_VALID_USER(state, data) {
    state.dataNoValidUser = data
  },
  DATA_TOOLTIP(state, apiExcel) {
    state.dataTooltip = apiExcel.data.data
  },
  SET_DATA_LIST_GROUP_NEW(state, data) {
    state.dataListGroupNew = data.data
  },
  SET_DATA_GROUP_NEW_CHILDREN(state, data) {
    state.dataGroupNewChildren = data.data
  },
  SET_DATA_GROUP_NEW_UPDATED(state, data) {
    state.dataGroupNewUpdated = data.data
  },
}

const actions = {
  // Gọi danh sách cơ cấu tổ chức cần thêm mới
  async doFetchGroupNewUpdated({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.GROUP_NEW_TREEVIEW_FROM_UPDATED, { params: payload })
      commit('SET_DATA_GROUP_NEW_UPDATED', data)
    } catch (e) {
      return null
    }
  },

  // Gọi dánh sách cơ cấu tổ chức cha
  async doFetchListGroupNew({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.GROUP_NEW_TREEVIEW_ROOT, { params: payload })
      commit('SET_DATA_LIST_GROUP_NEW', data)
    } catch (e) {
      return null
    }
  },

  // Gọi danh sách cơ cấu tổ chức con
  async doFetchGroupNewChildren({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.GROUP_NEW_TREEVIEW_CHILDREN, { params: payload })
      commit('SET_DATA_GROUP_NEW_CHILDREN', data)
    } catch (e) {
      return null
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
