/* eslint-disable no-empty */
import api from '../api'

const state = {
  textTrashBin: [],
}

const getters = {
}

const mutations = {
  // eslint-disable-next-line no-shadow
  TEXT_TRASH_BIN(state, data) {
    state.dataPositionJob = data
  },
}

const actions = {
  // =========================================lấy bảng người dùng có phân trang=============================================
  // eslint-disable-next-line consistent-return
  async doFetchListTrash({ commit }, payload) {
    try {
      const fetchListTrashData = await api.fetchListTrash(payload)
      if (fetchListTrashData.status === 200) {
        return fetchListTrashData.data
      }
    } catch (e) {
      return null
    }
  },
  //= =========================================khôi phục người dùng =====================================================
  // eslint-disable-next-line consistent-return
  async doRestoreToTrash({ commit }, data) {
    try {
      const fetchListTrashData = await api.restoreToTrash(data)
      if (fetchListTrashData.status === 200) {
      }
    } catch (e) {
      return null
    }
  },
  // ==========================================xóa người dùng ============================================================
  // eslint-disable-next-line consistent-return
  async doDeleteToTrash({ commit }, data) {
    try {
      const fetchListTrashData = await api.deleteToTrash(data)
      if (fetchListTrashData.status === 200) {
      }
    } catch (e) {
      return null
    }
  },
  // ======================================lấy dữ liệu nhóm người dùng có phân trang ====================================
  // eslint-disable-next-line consistent-return
  async doFetchListTrashGroup({ commit }, payload) {
    try {
      const fetchListTrashData = await api.fetchListTrashGroup(payload)
      if (fetchListTrashData.status === 200) {
        return fetchListTrashData.data
      }
    } catch (e) {
      return null
    }
  },
  // ==========================================khôi phục dữ liệu nhóm người dùng ===========================================
  // eslint-disable-next-line consistent-return
  async doRestoreToTrashGroup({ commit }, data) {
    try {
      const fetchListTrashData = await api.restoreToTrashGroup(data)
      if (fetchListTrashData.status === 200) {
      }
    } catch (e) {
      return null
    }
  },
  // ==========================================xóa nhóm người dùng =====================================================
  // eslint-disable-next-line consistent-return
  async doDeleteToTrashGroup({ commit }, data) {
    try {
      const fetchListTrashData = await api.deleteToTrashGroup(data)
      if (fetchListTrashData.status === 200) {
      }
    } catch (e) {
      return null
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
