export default [
  {
    path: '/contact',
    name: 'contact-list',
    component: () => import('@/views/contact-manager/pages/Contact.vue'),
    meta: {
      pageTitle: 'QUẢN LÝ LIÊN HỆ',
      breadcrumb: [
        {
          text: 'Thông tin liên hệ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contact-service',
    name: 'contact-service-list',
    component: () => import('@/views/contact-manager/custommer-contact.vue'),
    meta: {
      pageTitle: 'QUẢN LÝ KHÁCH HÀNGLIÊN HỆ',
      breadcrumb: [
        {
          text: 'Thông tin khách hàng liên hệ',
          active: true,
        },
      ],
    },
  },
]
