/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const recursiveData = data => {
  if (data.features && data.features.length > 0) {
    data.features.forEach(element => {
      recursiveData(element)
    })
  } else {
    data.data = {
      value: data.value,
    }
  }
}
const state = () => ({
  totalRecord: 0,
  dataCombobox: [],
  dataPermission: [],
})

const getters = {
  totalRecord: state => state.totalRecord,
  dataCombobox: state => state.dataCombobox,
  dataPermission: state => state.dataPermission,
}

const mutations = {
  SET_DATA_USER_TYPE_COMBOBOX(state, data) {
    state.dataCombobox = data.data
  },
  SET_DATA_USER_TYPE_PERMISSION(state, data) {
    state.dataPermission = data.data
  },
}

const actions = {

  // lấy danh sách kiểu người dùng có phân trang
  async doFetchUserTypeCombobox({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.USERTYPE_COMBOBOX, { params: { id: payload } })
      commit('SET_DATA_USER_TYPE_COMBOBOX', data)
    } catch (e) {
      return null
    }
  },

  // lấy danh sách kiểu người dùng có phân trang
  async doFetchUserTypePermission({ commit }, id) {
    try {
      const { data } = await await axiosApiInstance.get(ConstantsApi.USERTYPE_PERMISSION, { params: { id } })
      data.data.forEach(element => {
        element.state = {
          expanded: true,
        }
        recursiveData(element)
      })
      commit('SET_DATA_USER_TYPE_PERMISSION', data)
    } catch (e) {
      return null
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
