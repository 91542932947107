import axios from '@axios'

const state = {
  totalRecord: '',
  listWorker: [],
  alert: {
    name: 'Không có thông báo mới',
    color: 'success',
  },
  dataEdit: [],
  dataVaild: [],
  dataNoValid: [],

}

const getters = {
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_TOTAL_RECORD(state, data) {
    state.totalRecord = data
  },
  // eslint-disable-next-line no-shadow
  SET_LIST_WORKER(state, data) {
    state.listWorker = data
  },
  // eslint-disable-next-line no-shadow
  ALERT(state, data) {
    state.alert.name = data.name
    state.alert.color = data.color
  },
  // eslint-disable-next-line no-shadow
  EDIT(state, data) {
    state.dataEdit = data
  },
  // eslint-disable-next-line no-shadow
  SET_DATA_VALID(state, data) {
    state.dataVaild = data
  },
  // eslint-disable-next-line no-shadow
  SET_NO_DATA_VALID(state, data) {
    state.dataNoValid = data
  },
}

const actions = {
// ==============================================LIST=====================================
// --lấy dữ liệu list worker
  // eslint-disable-next-line consistent-return
  async doFetchListWorker({ commit }, data) {
    try {
      const res = await axios.get('/Worker/workers', { params: data })
      if (res.status === 200) {
        commit('SET_TOTAL_RECORD', res.data.data.totalRecord)
        commit('SET_LIST_WORKER', res.data.data.pageLists)
        return {
          oke: true,
          data: res.data.data.pageLists,
        }
      }
      return {
        oke: true,
        messageErr: `Lỗi ${res.status}`,
      }
    } catch (e) {
      return {
        oke: true,
        messageErr: e,
      }
    }
  },
  // lấy dữ liệu theo ID
  // eslint-disable-next-line consistent-return
  async fetchdataIdST({ commit }, data) {
    try {
      const resuft = await axios.get(`/Worker/workers/${data}`)
      if (resuft.status === 200) {
        commit('EDIT', resuft.data)
        return resuft.data
      }
    } catch (e) {
      // console.log(e)
    }
  },
  // phần thêm
  async add({ commit }, dataAdd) {
    try {
      const addBusiness01 = await axios.post('/Worker/workers', dataAdd)
      if (addBusiness01.status === 200) {
        commit('ALERT', { name: 'Thêm thành công!', color: 'success' })
      }
    } catch (e) {
      commit('ALERT', { name: 'Thêm không thành công!', color: 'danger' })
    }
  },
  // Phần sửa/
  async editST({ commit }, dataAdd) {
    try {
      const PostEditJod = await axios.put('Worker/workers', dataAdd)
      if (PostEditJod.status === 200) {
        commit('ALERT', { name: 'Sửa thành công!', color: 'success' })
      }
    } catch (e) {
      commit('ALERT', { name: 'Sửa không thành công!', color: 'success' })
    }
  },

  // =Lấy danh sách tỉnh===//
  // eslint-disable-next-line consistent-return
  async fecthCountry() {
    try {
      const resuft = await axios.get('/OfficalProfile/countries')
      if (resuft.status === 200) {
        return {
          oke: true,
          data: resuft.data,
        }
      }
      return {
        oke: false,
        data: null,
      }
    } catch (e) {
      return {
        oke: true,
      }
    }
  },
  // lấy vị trí công việc
  // eslint-disable-next-line consistent-return
  async getAllComboBox(xrt, id) {
    try {
      const resuft = await axios.get('/Position/get-combobox-position')
      if (resuft.status === 200) {
        return {
          ok: true,
          data: resuft.data,
        }
      }
    } catch (e) {
      // console.log(e)
    }
  },
  //* *******edit ********8 */
  // eslint-disable-next-line no-dupe-keys
  async editST({ commit }, dataAdd) {
    try {
      const PostEditJod = await axios.put('/Worker/workers', dataAdd)
      if (PostEditJod.status === 200) {
        commit('ALERT', { name: 'Sửa thành công!', color: 'success' })
      }
    } catch (e) {
      commit('ALERT', { name: 'Sửa không thành công!', color: 'danger' })
    }
  },
  // --Xóa dữ liệu
  // eslint-disable-next-line consistent-return
  async doDeleteWorker(xrt, dataId) {
    try {
      const res = await axios.post('/Worker/delete-worker', dataId)
      if (res.status === 200) {
        return {
          oke: true,
          data: {
            message: 'Xóa thành công',
            color: 'success',
          },
        }
      }
      return {
        oke: false,
        data: {
          message: 'Xóa không thành công',
          color: 'danger',
        },
      }
    } catch (error) {
      return {
        oke: false,
        data: {
          message: 'Xóa không thành công',
          color: 'danger',
        },
      }
    }
  },
  // ============Làm việc với file=============
  // --------------dowload file
  // file mẫu
  async dowloadFile(txt) {
    try {
      axios({
        url: '/Worker/generateTemplate', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'FileMauNguoiLaoDong.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  // file excel
  async downloadExcelAll() {
    try {
      axios({
        url: '/Worker/export-excel', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'NguoiLaoDong.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  // ----------------import file
  // eslint-disable-next-line consistent-return
  async getApiExcel({ commit }, data) {
    try {
      const apiExcel = await axios.post('/Worker/import-worker-from-json', data)
      if (apiExcel.status === 200) {
        commit('SET_DATA_VALID', apiExcel.data.data.filter(e => e.isSuccess === true))
        commit('SET_NO_DATA_VALID', apiExcel.data.data.filter(e => e.isSuccess === false))
        return {
          oke: true,
          data: apiExcel.data.data,
        }
      }
    } catch (e) {
      return {
        oke: false,
        message: e,
      }
    }
  },

}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
