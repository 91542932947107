import axios from 'axios'
import Vue from 'vue'

// eslint-disable-next-line import/prefer-default-export
export const axiosApiInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

Vue.prototype.$http = axiosApiInstance
