import axios from '@axios'

const state = {
  dataPositionJob: [],
  alertAddJob: { name: '', status: false },
  validWork: [],
  validNoWork: [],
  valueEdit: [],
  CheckCode_state: true,
  CheckName_state: true,
  totalPagesState: '',
}

const getters = {
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SETDATAPOSITIONJOB(state, data) {
    state.dataPositionJob = data
  },
  // eslint-disable-next-line no-shadow
  ADDJOBMUTATION(state, data) {
    state.dataPositionJob = state.dataPositionJob.unshift(data)
  },
  // eslint-disable-next-line no-shadow
  CHECKCODEMUTISION(state, data) {
    state.checkCode = data
  },
  // eslint-disable-next-line no-shadow
  CHECKNAMEMUTISION(state, data) {
    state.checkName = data
  },
  // eslint-disable-next-line no-shadow
  ALERTADDJOB(state, data) {
    state.alertAddJob.name = data
    state.alertAddJob.status = true
  },
  // eslint-disable-next-line no-shadow
  ALERTADDJOBCLOSE(state) {
    state.alertAddJob.status = false
  },
  // eslint-disable-next-line no-shadow
  VALIDWORKMUTATION(state, data) {
    state.validWork = data
  },
  // eslint-disable-next-line no-shadow
  VALIDNOWORKMUTATION(state, data) {
    state.validNoWork = data
  },
  // eslint-disable-next-line no-shadow
  EDITMUTATION(state, data) {
    state.valueEdit = data
  },
  // eslint-disable-next-line no-shadow
  CHECK_CODE(state, data) {
    state.CheckCode_state = data
  },
  // eslint-disable-next-line no-shadow
  CHECK_NAME(state, data) {
    state.CheckName_state = data
  },
  // eslint-disable-next-line no-shadow
  TOTAL_PAGE_MUTATION(state, data) {
    state.totalPagesState = data
  },
}

const actions = {
  // eslint-disable-next-line consistent-return
  async doFetchDataPositionJob({ commit }, data) {
    try {
      const resuft = await axios.get('Position/positions/', { params: data })
      if (resuft.status === 200) {
        commit('SETDATAPOSITIONJOB', resuft.data.data.pageLists)
        commit('TOTAL_PAGE_MUTATION', resuft.data.data.totalRecord)
        return resuft.data
      }
    } catch (e) {
      // console.log(e)
    }
  },
  // ====================================lấy dữ liệu theo ID ==================================
  // eslint-disable-next-line consistent-return
  async doFetchDataPositionJobToID({ commit }, data) {
    try {
      const resuft = await axios.get(`/Position/positions/${data}`)
      if (resuft.status === 200) {
        commit('EDITMUTATION', [resuft.data])
        return resuft.data
      }
    } catch (e) {
      // console.log(e)
    }
  },
  //= ====================Thêm dữ liệu vào vị trí công việc ====================================
  async addJobAction({ commit }, dataAdd) {
    try {
      const checkCode = await axios.get('/Position/existCode', { params: { code: dataAdd.code } })
      const checkName = await axios.get('/Position/existName', { params: { name: dataAdd.name } })
      if (checkCode.status === 200 && checkName.status === 200) {
        if (checkCode.data === false) {
          if (checkName.data === false) {
            // eslint-disable-next-line no-alert
            commit('ALERTADDJOB', 'Thêm thành công')
            const resuft = await axios.post('/Position/positions', dataAdd)
            if (resuft.status === 200) {
              commit('ADDJOBMUTATION', dataAdd)
            }
          } else {
            commit('ALERTADDJOB', 'nhập tên bị trùng')
          }
        } else {
          // eslint-disable-next-line no-alert
          commit('ALERTADDJOB', 'nhập mã bị trùng')
        }
      } else {
        // console.log('kiểm tra điều kiện lỗi')
      }
    } catch (e) {
      // console.log(`lỗi rồi ${e}`)
    }
  },
  // =================================ADD =========================================
  async add({ commit }, dataAdd) {
    try {
      const resuft = await axios.post('/Position/positions', dataAdd)
      if (resuft.status === 200) {
        commit('ALERTADDJOB', 'Thêm Thành Công!')
      }
    } catch (e) {
      // console.log(`lỗi rồi ${e}`)
    }
  },
  // =============================== download file mẫu về máy ===============================================
  async downloadSamplePositionJob() {
    try {
      axios({
        url: '/Position/generateTemplate', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'File mẫu vị trí công việc.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  // =============================== download file mẫu về máy ===============================================
  async downloadFileAll() {
    try {
      axios({
        url: '/Position/export-excel', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'ViTriCongViec.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    } catch (e) {
      // console.log(`download bị lỗi ${e}`)
    }
  },
  //= ============================================Kiểm tra tính hợp lên khi thêm file Exel================================//
  // eslint-disable-next-line consistent-return
  async getApiExcel({ commit }, data) {
    try {
      const apiExcel = await axios.post('/Position/import-position-from-json', data)
      if (apiExcel.status === 200) {
        commit('VALIDWORKMUTATION', apiExcel.data.data.filter(e => e.isSuccess === true))
        commit('VALIDNOWORKMUTATION', apiExcel.data.data.filter(e => e.isSuccess === false))
        return {
          oke: true,
          data: apiExcel.data.data,
        }
      }
    } catch (e) {
      return {
        oke: false,
        messageErr: e,
      }
    }
  },
  // ===================================================sửa vị trí công việc==============================================//
  async editJobAction({ commit }, dataAdd) {
    try {
      const PostEditJod = await axios.put('Position/positions', dataAdd)
      if (PostEditJod.status === 200) {
        commit('ALERTADDJOB', 'Sửa Thành Công!')
      }
    } catch (e) {
      // console.log(`lỗi rồi ${e}`)
    }
  },
  //= =========================Kiểm tra có trùng mã hay không===============================//
  // eslint-disable-next-line consistent-return
  async checkCode({ commit }, dataCheckCode) {
    try {
      const checkCode = await axios.get('/Position/existCode', { params: { code: dataCheckCode } })
      if (checkCode.status === 200) {
        commit('CHECK_CODE', checkCode.data)
        if (checkCode.data === true) {
          commit('ALERTADDJOB', 'Nhập mã bị trùng')
        }
        return checkCode
      }
    } catch (e) {
      // console.log(`lỗi rồi ${e}`)
    }
  },
  //= =========================Kiểm tra có trùng tên hay không===============================//
  async checkName({ commit }, dataCheckName) {
    try {
      const checkName = await axios.get('/Position/existName', { params: { name: dataCheckName } })

      if (checkName.status === 200) {
        commit('CHECK_NAME', checkName.data)
        if (checkName.data === true) {
          commit('ALERTADDJOB', 'Nhập tên bị trùng')
        }
      }
    } catch (e) {
      // console.log(`lỗi rồi ${e}`)
    }
  },
  // ===========================lẫu combo box vị trí công việc========================//
  // eslint-disable-next-line consistent-return
  async getAllComboBox(xrt, id) {
    try {
      const data = JSON.stringify(id)
      const resuft = await axios.get('/Position/get-combobox-position', { params: { ids: data } })
      if (resuft.status === 200) {
        return resuft
      }
    } catch (e) {
      // console.log(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async deletePositionJob({ commit }, model) {
    try {
      const data = await axios.post(`Position/delete-positions?positionId=${model.positionId}`, model.id)
      return data
    } catch (error) {
      return error.response.data
    }
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
