export default [
  {
    path: '/nc-gioi-thieu/list',
    name: 'nc-gioi-thieu-list',
    component: () => import('@/views/new-content-manager/pages/NCGioiThieu.vue'),
    meta: {
      pageTitle: 'Giới thiệu',
      breadcrumb: [
        {
          text: 'danh sách',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nc-gioi-thieu-add/',
    name: 'nc-gioi-thieu-add',
    component: () => import('@/views/new-content-manager/pages/Event/NCGioiThieuEvent.vue'),
    meta: {
      pageTitle: 'Giới thiệu',
      breadcrumb: [
        {
          text: 'danh sách',
          to: '/nc-gioi-thieu/list',
        },
        {
          text: 'thêm mới giới thiệu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nc-gioi-thieu-update/:id',
    name: 'nc-gioi-thieu-update',
    component: () => import('@/views/new-content-manager/pages/Event/NCGioiThieuEvent.vue'),
    meta: {
      pageTitle: 'Giới thiệu',
      breadcrumb: [
        {
          text: 'danh sách',
          to: '/nc-gioi-thieu/list',
        },
        {
          text: 'Cập nhật giới thiệu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nc-tin-tuc/list',
    name: 'nc-tin-tuc-list',
    component: () => import('@/views/new-content-manager/pages/NCTinTuc.vue'),
    meta: {
      pageTitle: 'Tin tức',
      breadcrumb: [
        {
          text: 'danh sách',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nc-tin-tuc-add/',
    name: 'nc-tin-tuc-add',
    component: () => import('@/views/new-content-manager/pages/Event/NCTinTucEvent.vue'),
    meta: {
      pageTitle: 'Tin tức',
      breadcrumb: [
        {
          text: 'danh sách',
          to: '/nc-tin-tuc/list',
        },
        {
          text: 'thêm mới tin tức',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nc-tin-tuc-update/:id',
    name: 'nc-tin-tuc-update',
    component: () => import('@/views/new-content-manager/pages/Event/NCTinTucEvent.vue'),
    meta: {
      pageTitle: 'Tin tức',
      breadcrumb: [
        {
          text: 'danh sách',
          to: '/nc-tin-tuc/list',
        },
        {
          text: 'Cập nhật tin tức',
          active: true,
        },
      ],
    },
  },
]
