export default [
  {
    path: '/User-Group',
    component: () => import(/* webpackChunkName: "user/projects" */ '@/views/management-user/User-Group/pages/List.vue'),
    name: 'User-Group',
    meta: {
      pageTitle: 'Nhóm người dùng',
      breadcrumb: [
        {
          text: 'Quản lý đơn vị',
          to: '/User-Group',
        },
        {
          text: 'Nhóm người dùng',
          active: true,
        },
      ],
    },
  },
  {
    path: '/User-Group/Add',
    component: () => import(/* webpackChunkName: "user/projects" */ '@/views/management-user/User-Group/pages/Create.vue'),
    name: 'User-Group-Add',
    meta: {
      pageTitle: 'Nhóm người dùng',
      breadcrumb: [
        {
          text: 'Quản lý đơn vị',
          to: '/User-Group',
        },
        {
          text: 'Nhóm người dùng',
          to: '/User-Group',
        },
        {
          text: 'Thêm nhóm người dùng',
          active: true,
        },
      ],
    },
  },
  {
    path: '/User-Group/List',
    component: () => import('@/views/management-user/User-Group/pages/components/ListImportFile.vue'),
    name: 'User-Group-List',
    meta: {
      pageTitle: 'Nhóm người dùng',
      breadcrumb: [
        {
          text: 'Quản lý đơn vị',
          to: '/User-Group',
        },
        {
          text: 'Nhóm người dùng',
          to: '/User-Group',
        },
        {
          text: 'Danh sách nhóm người dùng',
          active: true,
        },
      ],
    },
  },
  {
    path: '/User-Group/Edit/:id',
    component: () => import('@/views/management-user/User-Group/pages/Create.vue'),
    name: 'User-Group-Edit',
    meta: {
      pageTitle: 'Nhóm người dùng',
      breadcrumb: [
        {
          text: 'Quản lý đơn vị',
          to: '/User-Group',
        },
        {
          text: 'Nhóm người dùng',
          to: '/User-Group',
        },
        {
          text: 'Sửa nhóm người dùng',
          active: true,
        },
      ],
    },
  },
]
