/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

const state = () => ({
  dataListCarCatalog: [],
})

const getters = {
  dataListCarCatalog: state => state.dataListCarCatalog,
}

const mutations = {
  SET_DATA_LIST_CAR_CATALOG(state, data) {
    state.dataListCarCatalog = data.data
  },
}

const actions = {
  // Gọi danh sách loại xe [Phân trang]
  async doFetchListCarCatalog({ commit }, payload) {
    try {
      const { data } = await axiosApiInstance.get(ConstantsApi.GET_PAGING_CAR_CATEGORY, { params: payload })
      commit('SET_DATA_LIST_CAR_CATALOG', data)
    } catch (e) {
      return null
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
